'use client'

import { LinearProgress } from '@mui/material'
import localFont from 'next/font/local'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useTourState from '@/hooks/context/useTourState'

import { logoIndexClx, showIndexHeaderText } from '@/branding-config'
import { COMPANY } from '@/constants/env'
import { cn } from '@/utils/clsx'

import IndexDropdown from './IndexDropdown'
import IndexNavigation from './IndexNavigation'
import companyLogo from '../../../public/companyLogo.png'

const pinnacleFont = localFont({ src: '../../../public/Saans-Regular-1.woff2' })

const IndexHeader: React.FC = () => {
  const pathname = usePathname()
  const { loading, startLoading } = useLoadingState()
  const { t } = useTranslation()
  const { userTourRef4 } = useTourState()

  return (
    <>
      {loading && (
        <LinearProgress
          sx={{ position: 'absolute', zIndex: '100', width: '100%' }}
        />
      )}
      <section className='flex flex-col justify-center bg-surface p-4 text-on-surface shadow-md backdrop-blur-md lg:h-28 xl:px-20 dark:bg-dark-surface dark:text-dark-on-surface'>
        <div className='flex items-center justify-between'>
          <Link
            href='/'
            onClick={() => pathname !== '/' && startLoading()}
            className='flex items-center gap-6'
          >
            <Image
              src={companyLogo}
              alt='company logo'
              className={cn('cursor-pointer', logoIndexClx)}
            />
            {showIndexHeaderText && (
              <div className='flex flex-col text-left'>
                <span
                  className={cn(
                    'text-xl font-bold text-on-surface dark:text-dark-on-surface',
                    pinnacleFont.className
                  )}
                >
                  {COMPANY}
                </span>
                <div className='hidden gap-1 text-sm text-on-surface/60 md:block dark:text-dark-on-surface/60'>
                  {t('index-header')}
                </div>
              </div>
            )}
          </Link>
          <div ref={userTourRef4}>
            <div className='flex lg:hidden'>
              <IndexDropdown />
            </div>
            <div className='hidden lg:flex'>
              <IndexNavigation />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default IndexHeader
